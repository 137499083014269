<template>
	<div class="container-fluid dashboard-wrapper">
		<div class="row">
			<div class="col-lg-12">
				<div class="row">
					<div class="col-lg-4 col-md-6">
					  <router-link :to="{ name: 'courses' }">	
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{course_count}}</h2>
										<p class="mb-0 text-danger">Courses</p>
									</div>
								</div>
							</div>
						</div>
					  </router-link>	
					</div>
					<div class="col-lg-4 col-md-6">
					  <router-link :to="{ name: 'students' }">
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{student_count}}</h2>
										<p class="mb-0 text-danger">Students</p>
									</div>
								</div>
							</div>
						</div>
					  </router-link>	
					</div>
					<div class="col-lg-4 col-md-6">
					  <router-link :to="{ name: 'students' }">	
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{students_year}}</h2>
										<p class="mb-0 text-danger">Students This Year</p>
									</div>
								</div>
							</div>
						</div>
					  </router-link>	
					</div>
					<div class="col-lg-4 col-md-6">
					  <router-link :to="{ name: 'departments' }">	
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{department_count}}</h2>
										<p class="mb-0 text-danger">Departments</p>
									</div>
								</div>
							</div>
						</div>
					  </router-link>	
					</div>
					<div class="col-lg-4 col-md-6">
					  <router-link :to="{ name: 'centres' }">	
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{centre_count}}</h2>
										<p class="mb-0 text-danger">Centres</p>
									</div>
								</div>
							</div>
						</div>
					  </router-link>	
					</div>
					<div class="col-lg-4 col-md-6">
					  <router-link :to="{ name: 'branches' }"> 	
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{branch_count}}</h2>
										<p class="mb-0 text-danger">Branches</p>
									</div>
								</div>
							</div>
						</div>
					  </router-link>	
					</div>
					<div class="col-lg-4 col-md-6">
					 <router-link :to="{ name: 'applied.documents' }">	
						<div class="card card-block card-stretch card-height">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div class="mm-cart-text">
										<h2 class="font-weight-700">{{document_count}}</h2>
										<p class="mb-0 text-danger">Applied documents Count</p>
									</div>
								</div>
							</div>
						</div>
					 </router-link>	
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="row">
					<div class="col-lg-4">
						<div class="card card-block card-stretch card-height">
							<div class="card-header d-flex justify-content-between">
								<div class="header-title">
									<h6 class="card-title">New Students</h6>
								</div>
								<div class="card-header-toolbar d-flex align-items-center">
									<router-link :to="{ name: 'students' }" class="btn btn-sm btn-outline-primary view-more">View All Students</router-link>
								</div>
							</div>
							<div class="card-body">
								<ul class="list-inline p-0 m-0">
									
									<li class="media align-items-center mb-3" v-for="student in students" :key="student.id">
										                                       
										<div class="media-body">
											<h6>{{student.name}}</h6>
											<p class="mb-0 text-primary">{{student.mobile}}</p>
											<p class="mb-0">{{student.created_at | formatDateTime}}</p>
										</div>
									</li>

								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="card card-block card-stretch card-height">
							<div class="card-header d-flex justify-content-between">
								<div class="header-title">
									<h6 class="card-title">New Centres</h6>
								</div>
								<div class="card-header-toolbar d-flex align-items-center">
									<router-link :to="{ name: 'centres' }" class="btn btn-sm btn-outline-primary view-more">View All Centres</router-link>
								</div>
							</div>
							<div class="card-body">
								<ul class="list-inline p-0 m-0">
									
									<li class="media align-items-center mb-3" v-for="centre in centres" :key="centre.id">
										
										<div class="media-body">
											<h6>{{centre.centre}}</h6>											
											<p class="mb-0">{{centre.created_at | formatDateTime}}</p>
										</div>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="card card-block card-stretch card-height">
							<div class="card-header d-flex justify-content-between">
								<div class="header-title">
									<h6 class="card-title">Recently Applied Documents</h6>
								</div>
								<div class="card-header-toolbar d-flex align-items-center">
									<router-link :to="{ name: 'applied.documents' }" class="btn btn-sm btn-outline-primary view-more">View All Applied Documents</router-link>
								</div>
							</div>
							<div class="card-body">
								<ul class="list-inline p-0 m-0">
									
									<li class="media align-items-center mb-3" v-for="document in documents" :key="document.id">
										
										<div class="media-body">
											<h6>{{document.name}}</h6>
											<p class="mb-0">{{document.document_name}}</p>
											<p class="mb-0">{{document.created_at | formatDateTime}}</p>
										</div>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
	export default {
		name: "Dashbord",
		components: {},
		data() {
			return {
				course_count: null,
				student_count: null,
				students_year: null,
				department_count: null,
				centre_count: null,
				branch_count: null,
				document_count: null,
				courses: null,
				students: null,
				departments: null,
				centres: null,
				documents: null,
			};
		},
		created() {
			this.fetchDashboard();
		},
		methods: {
			fetchDashboard(page_url = null) {
				let url = "/api/dashboard";
				page_url = page_url || url;

				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.course_count = responseData.course_count;
						this.student_count = responseData.student_count;
						this.students_year = responseData.students_year;
						this.department_count = responseData.department_count;
						this.centre_count = responseData.centre_count;
						this.branch_count = responseData.branch_count;
						this.document_count = responseData.document_count;

						this.courses = responseData.courses;
						this.students = responseData.students;
						this.departments = responseData.departments; 
						this.centres = responseData.centres; 
						this.documents = responseData.documents;                        
						console.log(responseData);
					})
					.catch((err) => console.log(err));
			},
			
			getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},
		},
	};
</script>
